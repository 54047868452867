.base-form {
  @apply block justify-center text-left text-lg;
  margin: 0 auto;

  .base-form-input {
    @apply mb-5;
  }

  input:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    // background-color: transparent;
  }

  .base-form-button {
    @apply block justify-center mb-5 max-w-md;
    margin: 0 auto;
  }

  .base-form-group {
    @apply mb-5;
    @media (min-width: 768px) {
      margin: 5px auto;
      margin-bottom: 1.25rem;
    }
  }

  .base-form-error {
    @apply border-b-2 border-t-2 border-red-400 p-2 mb-3;
    font-size: 15px;
  }
}
