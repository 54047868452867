@use "@danielmackenzie8/react-widgets/scss/styles.scss" with (
  $components: (
    'Combobox',
    'DatePicker',
    'TimeInput',
  ),
  $widget-bg: rgba(243,244,246),
  $input-focus-bg: #ffffff,
  $widget-font-weight: 300,
  $widget-border-radius: 0.375rem,
  $input-box-shadow: reset,
  $input-padding-x: 1rem,
  $input-height: 42px,
  $widget-focus-box-shadow: 0 0 0 0 transparent,
  $input-focus-border-color: rgba(107,114,128),
  $input-border-color: transparent,
);

[type='text']:focus {
  box-shadow: 0 0 0 0 transparent,
}