.payment-page {
  text-align: center;
  .payment-form {
    @apply block justify-center pt-5 max-w-xl text-left text-lg;
    margin: 0 auto;

    button[type="submit"] {
      @apply mt-5;
    }
  }
}