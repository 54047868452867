@tailwind base;
@tailwind components;
@tailwind utilities;

/* Buttons */
.button {
  @apply w-full font-bold rounded-full border focus:outline-none;
}

.disabled {
  // @apply disabled:opacity-5;
  background-color: -internal-light-dark(
    rgba(239, 239, 239, 0.3),
    rgba(19, 1, 1, 0.3)
  ) !important;
  color: -internal-light-dark(
    rgba(16, 16, 16, 0.3),
    rgba(255, 255, 255, 0.3)
  ) !important;
  border-color: -internal-light-dark(
    rgba(118, 118, 118, 0.3),
    rgba(195, 195, 195, 0.3)
  ) !important;
}

/* Checkbox */
// .form-checkbox {
//   @apply rounded bg-gray-200 border-transparent focus:border-transparent focus:bg-gray-200 text-blue focus:ring-1 focus:ring-offset-2 focus:ring-gray-500;
// }


/* Text Sizing */
.text_small {
  @apply text-sm;
}
.text_medium {
  @apply text-base;
}
.text_large {
  @apply text-lg;
}

/* Anchors */
// .body_anchor {
//   @apply text-blue-light hover:underline hover:text-blue-lighter;
// }
.footer_anchor {
  @apply text-white font-thin hover:underline hover:text-gray-300;
}

/* Typography */
.header {
  @apply pt-4 text-4xl mb-4;
}
.subheader {
  @apply text-xl mb-4;
}

/* Key Value Pairs */
.key {
  @apply text-gray-600;
}
.value {
  @apply font-semibold;
}

/* Progress */
.progress {
  @apply px-1 py-2 mx-0 border-b-4 h-full;
}
.progress_description {
  @apply hidden md:inline-block text-sm md:text-lg;
}
.progress_header {
  @apply text-xs md:text-sm;
}
.progress_active {
  @apply border-kwarred;
}
.progress_incomplete {
  @apply opacity-50 border-kwargray-lighter;
}
.progress_complete {
  @apply border-kwarblue;
}
.progress.subquestion {
  @apply ml-4;
}
.progress_header.subquestion {
  @apply font-thin text-xs;
}
.progress_description.subquestion {
  @apply text-sm;
}
// .flex-1.subquestion {
//   @apply  ;
// }

h1 {
  @apply text-3xl mb-4;
}
a {
  @apply text-kwarred hover:underline;
}
p {
  @apply mb-4;
}

/* FOOTER */
footer {
  @apply pt-16;
}
.social .socialLink {
  @apply inline-block bg-kwarred hover:bg-kwarblue rounded p-2 mr-2;
}
.social .socialGlyph {
  @apply h-4;
}

/* FORMS */

.form-label {
  @apply block text-gray-700 text-sm font-bold mb-2;
}
.form-input, .form-select {
  @apply mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-300 focus:ring focus:ring-yellow-300 focus:ring-opacity-50;
}
.form-input:disabled, .form-select:disabled {
  @apply bg-gray-100 text-gray-500 cursor-not-allowed;
}
.form-checkbox {
  @apply rounded border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50;
}
textarea {
  @apply border-gray-300 rounded-lg;
}
.btn {
  @apply font-bold py-2 px-4 rounded;
}
.btn-primary {
  @apply bg-kwarred text-white shadow-sm hover:shadow-lg;
}
.btn-primary:disabled {
  @apply bg-gray-400 cursor-not-allowed;
}
.hintText {
  @apply font-normal mt-1 text-xs;
}
.errorWrapper {
  input,
  select,
  button {
    @apply bg-red-50 border-red-500;
  }
  input:focus {
    @apply bg-white;
  }
  .errorText {
    @apply text-red-800 font-bold mt-1 text-xs;
  }
  .rw-widget-picker {
    @apply border-red-500;
  }
  .rw-popup-container {
    button {
      background-color: initial;
    }
  }
  .rw-time-input,
  .rw-time-part-input:focus {
    @apply bg-red-50;
  }
}

/* Form Component Sizing */
.form_small {
  @apply px-2 py-1 text-sm;
}
.form_medium {
  @apply px-4 py-2;
}
.form_large {
  @apply px-8 py-4 text-lg;
} 

/* Error pages */
.error-page {
  @apply flex justify-center items-center;
  height: 400px;
}
