#login-form,
#code-form,
.mfa-form {
  @apply block justify-center pt-5 max-w-md text-center text-lg;
  margin: 0 auto;

  .login-input,
  .code-input {
    @apply mt-5;
  }

  .login-button,
  .code-button {
    @apply mt-5;
  }
}
