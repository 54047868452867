#adminPages {


  .admin-header {
    @apply bg-kwarred;
  }

  .hide {
    display: none;
  }

  .icon-height {
    max-height: 30px;
    width: auto;
  }

  .error {
    font-size: 0.875rem;
  }
}