.data-table {
  width: 100%;
  color: white;
  background-color: white;
}

.MuiTablePagination-selectLabel {
  padding-top: 11px;
} 

.css-levciy-MuiTablePagination-displayedRows {
  padding-top: 14px;
}

.MuiDataGrid-row {
  cursor: pointer;
}